/* =========
  Random Sections
  This Code is Licensed by Will-Myers.com
========== */

.hide-section {
  display: none;
}

.show-section {
  display: flex;
}

body:not(.sqs-edit-mode-active) .hidden-random-section {
  display:none;
}
body:not(.sqs-edit-mode-active) .random-hero-section {
  padding-top: var(--artificial-hero-top-padding, 0px)
}

.sqs-edit-mode-active .code-block [data-wm-plugin="random-sections"] {
  color: transparent;
  background: transparent;
  font-size: 0;
  position: absolute;
  line-height: 0;
  width: 100%;
  height: 25px;
  border: 1px dashed var(--headingLargeColor);
}

.sqs-edit-mode-active .code-block [data-wm-plugin="random-sections"]::after {
  content: 'Random Sections';
  color: var(--headingLargeColor);
  font-size: 12px;
  position: absolute;
  top: 50%;
  transform:translateX(25%);
}

/** 
* Fade In
**/
body:not(.sqs-edit-mode-active) .content-wrapper{
  opacity: var(--random-section-load-opacity, 0);
  transition: opacity .1s ease .2s;
}
body.wm-show-page .content-wrapper{
  opacity:1;
}
